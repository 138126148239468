import { getContentBlockListName } from '..';
import { getProductElementData, trackProductElementClick, trackProductListImpressions } from '../../tracking/list';

/**
 * Initialise Bloomreach Recommendation Engine content blocks.
 *
 * This function must be called on pages that include Bloomreach Recommendation
 * Engine content blocks, but no more than once per page load.
 */
export const initBloomreachRecommendationBlocks = () => {
  // Add an IntersectionObserver to track impressions when a recommendation
  // block becomes visible in the viewport.
  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) {
        continue;
      }

      // Unobserve, so we only record impressions once per block.
      observer.unobserve(entry.target);

      const list = getContentBlockListName(entry.target);

      // Add click event listeners to each product in the block and get the
      // tracking data from the DOM.
      const productLinks = entry.target.querySelectorAll('.product-preview__link');
      const items = [];
      for (const [position, productLink] of productLinks.entries()) {
        const data = getProductElementData(productLink, { position });

        items.push(data);

        productLink.addEventListener('click', (e) => trackProductElementClick(e, list));

        MV.loadScript('/javascript/mv2020/plp-swatches.js').then(() => {
          initSwatches(productLink.closest('.js-swatch-group'));
        });
      }

      // Record impressions for every product in the block, even those not
      // yet visible in the carousel.
      trackProductListImpressions({ list, items });
    }
  });

  document.querySelectorAll('.carousel--br-rec-engine').forEach((carousel) => {
    if (carousel.querySelector('[class*="js-carousel--br-rec-engine-block"].slick-initialized')) {
      observer.observe(carousel);
    } else {
      $(carousel).one('init', () => {
        observer.observe(carousel);
      });
    }
  });


};

const initSwatches = (container, changeCallback) => {
  if (!container) {
    return;
  }

  var sw = new ProductSwatchGroup(
    container,
    function(elem) {
        /**
         * Injecting application logic for changing the card.
         * In this callback you can use "elem" as a ProductSwatch instance.
         */
        var container = elem.controller.container;
        var link = container.querySelector('.js-swatch-group_link');

        if (typeof link != 'undefined') {
            try {
                link.href = elem.getData('url');

                const swatchGroupImage = link.querySelector('.js-swatch-group_image img');
                const imageUrl = elem.getData('image');
                swatchGroupImage.src = imageUrl;
                swatchGroupImage.srcset = imageUrl + ' 330w';

                const swatchGroupHover = link.querySelector('.js-swatch-group_hover img');
                const hoverUrl = elem.getData('hover');
                swatchGroupHover.src = hoverUrl;
                swatchGroupHover.srcset = hoverUrl + '  330w';

                link.querySelector('.js-swatch-group_description').textContent = elem.getData('label');
                const price = link.querySelector('.js-swatch-group_price');
                let wasPrice = price.querySelector('span:not([js-swatch-group_discount])');
                if (typeof elem.getData('wasprice') != 'undefined' && elem.getData('wasprice') != '') {
                    price.textContent = elem.getData('price');
                    if (typeof wasPrice == 'undefined' || !wasPrice) {
                        wasPrice = document.createElement('span');
                        if (elem.getData('discount')) {
                            var discount = document.createElement('span');
                            discount.classList.add('js-swatch-group_discount');
                            discount.classList.add('product-preview__price--discount');
                            discount.textContent = ' ' + elem.getData('discount');
                            price.append(discount);
                        }
                    } else {
                        var discount = price.querySelector('span.js-swatch-group_discount');
                        if (typeof discount == 'undefined' || !discount) {
                            if (elem.getData('discount')) {
                                discount = document.createElement('span');
                                discount.classList.add('js-swatch-group_discount');
                                discount.classList.add('product-preview__price--discount');
                                discount.textContent = ' ' + elem.getData('discount');
                                price.append(discount);
                            }
                        }
                    }
                    wasPrice.textContent = elem.getData('wasprice');
                    price.prepend(wasPrice);
                    if (!price.classList.contains('product-preview__price--was')) {
                        price.classList.add('product-preview__price--was');
                    }
                } else {
                    if (typeof wasPrice != 'undefined' && wasPrice) {
                        wasPrice.remove();
                    }
                    price.textContent = elem.getData('price')
                    if (price.classList.contains('product-preview__price--was')) {
                        price.classList.remove('product-preview__price--was');
                    }
                }

                const lightningDealAttribs = elem.getData('lightningDeal');
                let badgeMessage = elem.getData('badge');
                if (lightningDealAttribs && lightningDealAttribs !== null) {
                    if (badgeMessage == '') {
                        badgeMessage = 'FLASH SALE';
                    }

                    const badge = link.querySelector('.js-flash-badge');
                    if (badge && typeof badge != 'undefined') {
                        badge.classList.remove('flash-badge--hidden');
                        badge.textContent = badgeMessage;
                    }
                    window.plp?.initLightningDeal(window.plp.options.getOption('lightning_deal_selector', '.lightning-deal'), window.plp.translation);
                } else {
                    const badge = link.querySelector('.js-flash-badge');
                    if (badge && typeof badge != 'undefined') {
                        if (badgeMessage) {
                            badge.textContent = badgeMessage;
                            badge.classList.remove('flash-badge--hidden');
                        } else {
                            badge.classList.add('flash-badge--hidden');
                        }
                    }
                }

              for (const attrib of ['gaid', 'galabel', 'category', 'price', 'discount']) {
                link.dataset[attrib] = elem.getData(attrib);
              }
            } catch (err) {
                console.log(err.message);
            }
        }

        trackProductListImpressions({
          list: getContentBlockListName(container),
          items: [getProductElementData(elem.element, { position: elem.controller.container.dataset.slickIndex, price: elem.getData('pricesimple') })]
      });
    },
    { debug: console.debug }
  );

  sw.init();
}
